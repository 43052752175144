/* eslint-disable */
import React from 'react';

import TitlePages from 'components/_base/TitlePages';

import { ContextPedido } from './context-pedido';
import { ContextEntities } from './context-entities-pedido';

export default function Materiais(props) {
  const { pedido, setPedido, refreshPedido } = React.useContext(ContextPedido);
  const { materiaisComunicacao } = React.useContext(ContextEntities);



  //let materiais = [].concat(...(tipoMateriais || []).map(t => t.materiaisComunicacao));
  return (
    <>
      <TitlePages title="Selecione os Materiais" />
      {
        materiaisComunicacao?.map(m => {
          let selecionado = pedido.solicitacoes.findIndex((s) => s.materialComunicacaoId === m.materialComunicacaoId) !== -1;

          // for (let s of pedido.solicitacoes){
          //   if (m.materialComunicacaoId === s.materialComunicacaoId){
          //     selecionado = true
          //   }

          // }

          // if (pedido.solicitacoes.findIndex((s) => s.materialComunicacaoId === m.materialComunicacaoId) === -1){
          //   selecionado = true
          // }

          return (
            <button key={m.materialComunicacaoId} type="button" className={`btn text-white m-2 ${selecionado ? "bg-selected" : "bg-non-selected"}`} onClick={() => {
              pedido.solicitacoes = pedido.solicitacoes || [];

              let index = pedido.solicitacoes.findIndex((s) => s.materialComunicacaoId === m.materialComunicacaoId);
              if (index === -1) {

                let solicitacao = {
                  materialComunicacaoId: m.materialComunicacaoId,
                  materialComunicacao: m,

                  solicitacaoDados: {},
                  solicitacaoProdutos: []
                }

                pedido.solicitacoes.push(solicitacao)
              }
              else {
                pedido.solicitacoes.splice(index, 1)
              }

              refreshPedido();
            }}>{m.nome}</button>
          )
        })
      }

    </>
  )
}
