/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FormGroup, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationArrow,
  faAngleLeft,
} from '@fortawesome/free-solid-svg-icons';

import { ADD_CHAMADO } from 'services/Chamado';
import { GET_USUARIO } from 'services/Usuario';
import TitlePages from 'components/_base/TitlePages';
import { Context } from 'context/AuthContext';
import SingleSelect from 'components/_base/SelectSingle';
import InputFileCustom from 'components/_base/InputFile';

import './styles.scss';

const ContentChamado = () => {
  const history = useHistory();
  const [chamado, setChamado] = useState({});
  const [list, setList] = useState([]);
  const userAuth = JSON.parse(localStorage.getItem('encartes_dpa_site'));
  const [errorMessage, setErrorMessage] = useState('');

  const { getMotivoSelectList } = useContext(Context);
  const { showLoader } = useContext(Context);

  const onSubmit = async () => {
    const formDataChamado = new FormData();

    formDataChamado.append('ChamadoTipoId', chamado.chamadoTipoId);
    formDataChamado.append('Mensagem', chamado.mensagem);
    formDataChamado.append('UsuarioId', chamado.usuarioId);
    formDataChamado.append('Arquivo', chamado.arquivo);

    if (chamado.chamadoTipoId === null) {
      setErrorMessage('Selecione o motivo do seu chamado!');
    } else if (chamado.mensagem === '') {
      setErrorMessage('Escreva sua mensagem no campo abaixo!');
    } else {
      showLoader(true);
      const data = await ADD_CHAMADO(formDataChamado);

      if (data.errors == null) {
        chamado.errors = null;
        history.push('/chamado-enviado');
      } else {
        setErrorMessage('Não foi possível executar esta operação!');
      }
    }
    showLoader(false);
  };

  const geMotivoId = (value) => {
    setChamado({ ...chamado, label: value });
  };

  useEffect(() => {
    (async () => {
      showLoader(true);
      setList(await getMotivoSelectList());

      const data = await GET_USUARIO(userAuth.userId);

      setChamado({
        usuarioId: data.value.usuarioId,
        chamadoTipoId: null,
        mensagem: '',
        errors: '',
      });

      showLoader(false);
    })();
  }, []);

  async function onChangeWithName(value, name) {
    setChamado({
      ...chamado,
      [name]: value,
    });
  }

  function onChangeInput(event) {
    const { value, name } = event.target;

    setChamado({
      ...chamado,
      [name]: value,
    });
  }
  function onChangeFile(event, name) {
    setChamado({
      ...chamado,
      [name]: event.target.files[0],
    });
  }

  return (
    <div className="wrapper-chamado">
      <TitlePages title="ABRIR CHAMADO" />
      <form className="contents">
        <div className="motivo row col-lg-8 col-md-8">
          <label className="">Selecione o motivo</label>
          <FormGroup className="select col-lg-5 col-md-5">
            <SingleSelect
              value={chamado.chamadoTipoId}
              name="chamadoTipoId"
              onChange={onChangeWithName}
              options={list}
            />
          </FormGroup>
        </div>

        {errorMessage && (
          <Alert
            variant="danger"
            onClose={() => setErrorMessage('')}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}
        <FormGroup className=" mensagem col-lg-8 col-md-8">
          <textarea
            type="input"
            name="mensagem"
            onChange={onChangeInput}
            className="col-lg-12"
            placeholder="Digite sua mensagem"
          ></textarea>
        </FormGroup>

        <div className="enviar col-lg-8 col-md-8">
          <FormGroup>
            <InputFileCustom
              className="input"
              name="arquivo"
              onChange={onChangeFile}
              file={chamado.arquivo}
            ></InputFileCustom>
          </FormGroup>
          <FormGroup>
            <Button
              id="submit"
              onClick={onSubmit}
              className="col-lg-3 col-md-3 col-sm-3 botao"
            >
              <FontAwesomeIcon className="icon" icon={faLocationArrow} />
              ENVIAR
            </Button>
          </FormGroup>
        </div>

        <FormGroup className="voltar col-lg-8 col-md-8">
          <a className="btn btn-primary btnVoltar" href="/atendimento">
            <FontAwesomeIcon className="icon" icon={faAngleLeft} />
            VOLTAR
          </a>
        </FormGroup>
      </form>
    </div>
  );
};

export default ContentChamado;
