/* eslint-disable */
import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Button,
  Row,
  Col,
  Alert,
  Tabs,
  Tab,
  Sonnet,
  FormGroup,
  Table,
} from 'react-bootstrap';
import {
  FaHome,
  FaAngleRight,
  FaSearch,
  FaPeopleCarry,
  FaUserCheck,
  FaFileUpload,
  FaChevronRight,
  FaChevronLeft,
  FaPlusCircle,
  FaCalendarCheck,
  FaFileDownload,
  FaTelegramPlane,
  FaImages,
} from 'react-icons/fa';

import './styles.scss';
import history from 'routes/history';
import { GET_EXTRATO_CUSTO_CND_BYTES, GET_EXTRATO_CUSTO_TRADE_BYTES } from 'services/ExtratoSolicitacaoCusto';
import TitlePages from 'components/_base/TitlePages';
import LoaderAction from 'store/modules/loader/actions';
//import InputFileCustom from "../../../atoms/InputFile";
import {
  faCaretRight,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from 'context/AuthContext';
import SingleSelect from 'components/_base/SelectSingle';

const ExtratoDownloads = (props) => {
  const { showLoader } = useContext(Context);

  const { regionais } = props;
  const filtro = props.filtro;

  async function downloadCNDExcel(regionalId) {
    showLoader(true);
    let paramFiltro = { ...filtro };
    paramFiltro.regionalId = regionalId;
    let data = await GET_EXTRATO_CUSTO_CND_BYTES(paramFiltro);

    forceDownload(data, 'cnd');

    showLoader(false);
  }

  async function downloadTRADEExcel(regionalId) {
    showLoader(true);
    let paramFiltro = { ...filtro };
    paramFiltro.regionalId = regionalId;
    let data = await GET_EXTRATO_CUSTO_TRADE_BYTES(paramFiltro);

    forceDownload(data, 'trade');

    showLoader(false);
  }

  function forceDownload(data, tipo) {
    if (data && data.value) {
      let today = new Date();
      let date = `${today.getFullYear()}_${(today.getMonth() + 1)}_${today.getDate()}`;
      let file = _base64ToByteArray(data.value);
      let blob = new Blob([file], { type: 'arraybuffer' });
      let fileName = `extrato_custo_${tipo || ''}_${date}.xlsx`;
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      let fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();
    }
  }

  function _base64ToByteArray(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    return bytes;
  }

  function renderRegionais(option) {
    if ((option?.options || []).length > 0) {
      return (
        <>
          {option.options.map((o, index) => (
            <React.Fragment key={index}>
              {renderRegionais(o)}
            </React.Fragment>
          ))}
        </>
      )
    }

    if (!filtro?.regionalId > 0 || filtro?.regionalId == option?.value) {
      var regionalId = option?.value ?? 0;

      var mes = filtro?.mes;
      if (filtro?.mes < 10) {
        mes = '0' + mes;
      }

      if (option.value > 0) {
        return (
          <tr>
            <td>{option?.label}</td>
            <td>
              {mes}/{filtro?.ano}
            </td>
            <td>
              <Button className="btn-extrato mr-3" disabled={''} onClick={() => {
                downloadCNDExcel(regionalId);
              }}
              >
                <FaFileDownload size="20" /> Extrato CND
              </Button>

              <Button className="btn-extrato" disabled={''} onClick={() => {
                downloadTRADEExcel(regionalId);
              }}
              >
                <FaFileDownload size="20" /> Extrato TRADE
              </Button>
            </td>
          </tr>
        )
      }
    }
  }

  return filtro?.ano > 0 && filtro?.mes > 0 ? (
    <div className="extrato-downloads">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Regional</th>
            <th>Mês</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            regionais?.map((option, index) => (
              <React.Fragment key={index}>
                {renderRegionais(option)}
              </React.Fragment>
            ))
          }
        </tbody>
      </Table>
    </div>
  ) : (
    <div></div>
  );
};

export default ExtratoDownloads;
