/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faAngleRight,
  faHome,
} from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import TitlePages from 'components/_base/TitlePages';
import history from 'routes/history';
import api from 'api';
import RequestMaterialsActions from 'store/modules/request-materials/actions';
import LoaderAction from 'store/modules/loader/actions';
import InputFileCustom from 'components/_base/InputFile';
import { GET_RASCUNHO, UPDATE_RASCUNHO } from 'services/Rascunho';
import {
  GET_URL_LOGO_PADRAO,
  GET_LIST_LOJA,
  UPLOAD_LOGO,
} from 'services/Solicitacao';

const ContentChooseTheCustomer = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [rascunho, setRascunho] = useState({});

  const [search, setSearch] = useState('');
  const [client, setClient] = useState({ cidade: {} });
  const [clientsList, setClientsList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [clientSelected, setClientSelected] = useState(null);
  const [withBrand, setWithBrand] = useState(true);
  const [logoPadrao, setLogoPadrao] = useState('');

  useEffect(() => {
    (async () => {
      dispatch(LoaderAction.showLoader(true));
      const resultLogoPadrao = await GET_URL_LOGO_PADRAO();

      if (resultLogoPadrao.value) setLogoPadrao(resultLogoPadrao.value);

      if (params.id) {
        const { value } = await GET_RASCUNHO(params.id);
        setRascunho({ ...value });
        setClient(value.loja);
      } else {
        setRascunho({ ...rascunho, rascunhoId: 0 });
      }

      dispatch(LoaderAction.showLoader(false));
    })();
  }, [false]);

  const searchByCodeCNPJ = (event) => {
    setSearch(event.target.value);
  };

  const searchByName = (event) => {
    setSearch(event.target.value);
  };

  const onSearch = async () => {
    dispatch(LoaderAction.showLoader(true));
    const data = await GET_LIST_LOJA({ codigoCnpjRazaoSocial: search });

    if (data.value.length === 1) {
      setClient(value[0]);
      setRascunho({
        ...rascunho,
        lojaId: data.value[0].lojaId,
        loja: { ...data.value[0] },
      });

      const data = UPDATE_RASCUNHO({
        ...rascunho,
        lojaId: value[0].lojaId,
        loja: { ...value[0] },
      });

      const action = RequestMaterialsActions.setCustomer(value[0]);
      dispatch(action);

      document.getElementById('set-brand').checked = true;
    } else {
      setClientsList(value);
      setShowModal(true);
    }
    dispatch(LoaderAction.showLoader(false));
  };

  const selectClient = (index) => (event) => {
    setClientSelected(index);
    setClient(clientsList[index]);

    setRascunho({
      ...rascunho,
      lojaId: clientsList[index].lojaId,
      loja: { ...clientsList[index] },
    });

    const action = RequestMaterialsActions.setCustomer(clientsList[index]);
    dispatch(action);

    setWithBrand(rascunho.rascunhoId === 0 || clientsList[index].urlLogo);

    document.getElementById('set-brand').checked = true;
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const setSettingsBrand = (event) => {
    setWithBrand(event.target.value === 'true');
  };

  const redirectPage = (route) => {
    history.push(`/selecione-os-materiais/${rascunho.rascunhoId}`);
  };

  const uploadLogo = async (event) => {
    event.preventDefault();

    dispatch(LoaderAction.showLoader(true));
    const formData = new FormData();

    formData.append('lojaId', client.lojaId);
    formData.append('file', event.target.files[0]);

    const data = await UPLOAD_LOGO(formData);

    setClient({ ...client, urlLogo: data.value });

    dispatch(LoaderAction.showLoader(false));
  };

  return (
    <div className="wrapper-content-choose-the-customer">
      <TitlePages title="Escolha o Cliente" />
      <div className="content-choose-customer">
        <div>
          <label htmlFor="">Código ou CNPJ do Cliente</label>
          <div>
            <input type="text" onChange={searchByCodeCNPJ} />
            <div className="icon" onClick={onSearch}>
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </div>
        </div>
        <div>ou</div>
        <div>
          <label htmlFor="">Razão Social</label>
          <div>
            <input type="text" onChange={searchByName} />
            <div className="icon" onClick={onSearch}>
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={hideModal} className="modal-busca-loja">
        <Modal.Header closeButton className="header">
          <Modal.Title>Clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Codigo</th>
                <th>CNPJ</th>
                <th>Razão Social</th>
                <th>Endereço</th>
              </tr>
            </thead>
            <tbody>
              {clientsList.map((item, index) => {
                return (
                  <tr
                    className={
                      clientSelected === index
                        ? 'tr-selecao-loja tr-selecionado'
                        : 'tr-selecao-loja'
                    }
                    key={index}
                    onClick={selectClient(index)}
                    style={{
                      backgroundColor:
                        clientSelected === index
                          ? 'rgba(40, 109, 184,.5)'
                          : 'inherit',
                    }}
                  >
                    <td>{item.codigo}</td>
                    <td>{item.cnpj}</td>
                    <td>{item.razaoSocial}</td>
                    <td>{`${item.logradouro}, ${item.numero} - ${item.cidade.nome}-${item.cidade.uf}`}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button
            variant="primary"
            onClick={hideModal}
            className="button-close"
          >
            CONCLUIR
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="display-client">
        <div>
          <div>
            <label htmlFor="">Código: </label>
            <span>{client.codigo ? client.codigo : ''}</span>
          </div>

          <div>
            <label htmlFor="">Razão Social: </label>
            <span>{client.razaoSocial ? client.razaoSocial : ''}</span>
          </div>

          <div>
            <label htmlFor="">CNPJ: </label>
            <span>{client.cnpj ? client.cnpj : ''}</span>
          </div>

          <div>
            <label htmlFor="">CEP: </label>
            <span>{client.cep ? client.cep : ''}</span>
          </div>

          <div>
            <label htmlFor="">Logradouro: </label>
            <span>{client.logradouro ? client.logradouro : ''}</span>
          </div>

          <div>
            <label htmlFor="">Número: </label>
            <span>{client.numero ? client.numero : ''}</span>
          </div>

          <div>
            <label htmlFor="">Bairro: </label>
            <span>{client.bairro ? client.bairro : ''}</span>
          </div>

          <div>
            <label htmlFor="">Cidade: </label>
            <span>
              {client.cidade.nome
                ? client.cidade.nome + ' - ' + client.cidade.uf
                : ''}
            </span>
          </div>
        </div>
        <div className="set-settings-brand">
          <div style={{ opacity: client.codigo ? 1 : 0 }}>
            <input
              type="radio"
              id="set-brand"
              name="with-brand"
              onClick={setSettingsBrand}
              value={true}
              checked={withBrand}
            />
            <label htmlFor="set-brand">Com Logo</label>

            <input
              type="radio"
              id="set-no-brand"
              name="with-brand"
              onClick={setSettingsBrand}
              value={false}
              checked={!withBrand}
            />
            <label htmlFor="set-no-brand">Sem Logo</label>

            {withBrand ? (
              <div>
                {client.urlLogo && (
                  <img
                    className="img-logo"
                    src={client.urlLogo ? client.urlLogo : ''}
                    alt=""
                  />
                )}
                <div style={{ marginTop: '20px' }}></div>
                <InputFileCustom
                  name="Logo"
                  onChange={uploadLogo}
                ></InputFileCustom>
              </div>
            ) : (
              <div>
                <div className="msg-logo-padrao">
                  Seus materiais serão impressos com o logo padrão.
                </div>
                <img className="img-logo" src={logoPadrao} alt="" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="buttons-navigation">
        <button className="btn btn-primary">
          <span>
            <FontAwesomeIcon icon={faHome} />
          </span>
          <span>VOLTAR PARA A PÁGINA INICIAL</span>
        </button>
        <button onClick={redirectPage} className="btn btn-primary">
          <span>AVANÇAR</span>
          <span>
            <FontAwesomeIcon icon={faAngleRight} />
          </span>
        </button>
      </div>
    </div>
  );
};

export default ContentChooseTheCustomer;
