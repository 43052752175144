import React from 'react';

import Layout from 'components/Layout';
import ContentBancoArtes from './ContentBancoArtes';

const BancoArtes = () => {
  return (
    <>
      <Layout>
        <ContentBancoArtes />
      </Layout>
    </>
  );
};

export default BancoArtes;
