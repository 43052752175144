/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import Header from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import { GET_LIST_CHAMADO_POR_USUARIO } from 'services/Chamado';
import TitlePages from 'components/_base/TitlePages';
import { Context } from 'context/AuthContext';
import { dateFormat } from 'helpers/Date.Helper';
import history from 'routes/history';

import './styles.scss';

const ContentFollowChamado = () => {
  const userAuth = JSON.parse(localStorage.getItem('encartes_dpa_site'));
  const [chamados, setChamados] = useState([]);
  const { showLoader } = useContext(Context);

  useEffect(() => {
    (async () => {
      showLoader(true);

      const data = await GET_LIST_CHAMADO_POR_USUARIO(userAuth.userId);

      setChamados(data.value);
      showLoader(false);
    })();
  }, []);

  const visualizarClick = async (id) => {
    history.push(`/chat?id=${id}`);
  };

  return (
    <div className="wrapper-acompanhar-chamados">
      <TitlePages title="ACOMPANHAR CHAMADOS" />
      <div className="table-query ">
        <div className="table-responsive">
          <table className="table table-striped ">
            <thead>
              <tr>
                <th>Nº</th>
                <th>Criado em</th>
                <th>Criador</th>
                <th>Título</th>
                <th>Status</th>
                <th>Modificado</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {chamados.map((chamadoItem) => (
                <tr>
                  <td
                    style={{
                      color:
                        chamadoItem.dataFinalizacao !== null ? 'red' : null,
                    }}
                  >
                    {chamadoItem.chamadoId}
                  </td>
                  <td
                    style={{
                      color:
                        chamadoItem.dataFinalizacao !== null ? 'red' : null,
                    }}
                  >
                    {chamadoItem.chamadoTipo.nome}
                  </td>
                  <td
                    style={{
                      color:
                        chamadoItem.dataFinalizacao !== null ? 'red' : null,
                    }}
                  >
                    {chamadoItem.dataFinalizacao !== null
                      ? 'Finalizado'
                      : chamadoItem.chamadoRespostas[
                          chamadoItem.chamadoRespostas.length - 1
                        ].usuario.usuarioId === chamadoItem.usuarioId
                      ? 'Chamado enviado'
                      : 'Chamado respondido '}
                  </td>
                  <td
                    style={{
                      color:
                        chamadoItem.dataFinalizacao !== null ? 'red' : null,
                    }}
                  >
                    {dateFormat(chamadoItem.dataCriacao, true)}
                  </td>
                  <td
                    style={{
                      color:
                        chamadoItem.dataFinalizacao !== null ? 'red' : null,
                    }}
                  >
                    {chamadoItem.chamadoRespostas != null
                      ? dateFormat(
                          chamadoItem.chamadoRespostas[
                            chamadoItem.chamadoRespostas.length - 1
                          ].data,
                          true
                        )
                      : dateFormat(chamadoItem.dataCriacao, true)}
                  </td>
                  <td className="text-center">
                    <Button
                      style={{
                        backgroundColor:
                          chamadoItem.dataFinalizacao !== null
                            ? 'red'
                            : chamadoItem.chamadoRespostas[
                                chamadoItem.chamadoRespostas.length - 1
                              ].usuario.usuarioId === chamadoItem.usuarioId
                            ? null
                            : '#28a745 ',
                        borderColor:
                          chamadoItem.dataFinalizacao !== null
                            ? 'red'
                            : chamadoItem.chamadoRespostas[
                                chamadoItem.chamadoRespostas.length - 1
                              ].usuario.usuarioId === chamadoItem.usuarioId
                            ? null
                            : '#28a745 ',
                      }}
                      className="table-button"
                      onClick={() => visualizarClick(chamadoItem.chamadoId)}
                    >
                      Visualizar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="voltar ">
          <Button className="btnVoltar" href="/atendimento">
            <FontAwesomeIcon className="icon" icon={faAngleLeft} />
            VOLTAR
          </Button>
        </div>
      </div>
    </div>
  );
};

const FollowChamado = () => {
  return (
    <>
      <Header />
      <ContentFollowChamado />
      <Bottom />
    </>
  );
};

export default FollowChamado;
