/* eslint-disable */
import React from 'react';
import { Table } from 'react-bootstrap';
import { ContextPedido } from '../context-pedido';
import { ContextEntitiesSolicitacao } from './context-entities-solicitacao';
import { ContextSolicitacao } from './context-solicitacao';
import { Context } from 'context/AuthContext';

import { GET_SOLICITACAO_PRODUTO } from 'services/Solicitacao';

export default function Produtos(props) {
  const { pedido, refreshPedido } = React.useContext(ContextPedido);
  const { solicitacao } = React.useContext(ContextSolicitacao)
  const { configProdutos } = React.useContext(ContextEntitiesSolicitacao)
  const { showLoader } = React.useContext(Context)

  //const [vetor, setVetor] = React.useState([])

  console.log('configProdutos: ', configProdutos);
  console.log('solicitacao: ', solicitacao)

  async function addProduto(p) {
    showLoader(true);

    const result = await GET_SOLICITACAO_PRODUTO({
      lojaId: pedido.lojaId,
      produtoId: p.produtoId,
    });

    showLoader(false);

    let solicitacaoProduto = result.value;

    if (solicitacao.solicitacaoProdutos.findIndex(p => p.produtoId === solicitacaoProduto.produtoId) !== -1) {
      return;
    }

    solicitacao.solicitacaoProdutos.push(solicitacaoProduto);
    //solicitacao.solicitacaoProdutos = [...solicitacao.solicitacaoProdutos];
    refreshPedido();

    console.log('teste');


    // if (!result?.value)
    //   return undefined;

    // //pra funcionar no mobile
    // let solicitacaoProduto = { ...result.value };
    // solicitacaoProduto.produto = result.value;
    // //--

    // return solicitacaoProduto;
  }

  // function excluirProduto(p){
  //   let copiaVetor = [...solicitacao.solicitacaoProdutos]
  //   copiaVetor.splice(p,1);
  //   return copiaVetor;
  //   refreshPedido()
  // }

  function excluirProduto(p){
    solicitacao.solicitacaoProdutos.splice(p,1);

    refreshPedido()
  }

  return (
    <>

      <div className="row m-0">
        <div className="col-lg-6">
          <h1>Produtos</h1>
          <table>
            <tbody>
              {
                configProdutos.produtos?.map(p =>
                  <tr key={p.produtoId}>
                    <td>{p.nome}</td>
                    <td><button onClick={() => addProduto(p)}>Adicionar</button></td>
                  </tr>

                )
              }
            </tbody>
          </table>
        </div>
        <div className="col-lg-6">
          <h1>Produtos Adicionados ({solicitacao.solicitacaoProdutos.length})</h1>
          <table>
            <tbody>
              {
                solicitacao.solicitacaoProdutos.map(p =>
                  <tr key={p.produtoId}>
                    <td>{p.nome}</td>
                    <h4>Preço</h4>
                    <td>
                      <input type='number' value={p.preco} onChange={(e) => {
                        p.preco = e.target.value;
                        refreshPedido()
                      }}
                      ></input>
                    </td>
                    <td><button onClick={() => excluirProduto(p)} >Excluir</button></td>
                  </tr>

                )
              }
            </tbody>
          </table>
        </div>
      </div>

    </>
  )
}
