/* eslint-disable */
import React from 'react';
import { ContextEntitiesSolicitacaoProvider } from './context-entities-solicitacao';
import { ContextPedido } from '../context-pedido';
import { ContextSolicitacao } from './context-solicitacao';

export default function Solicitacao(props) {
  const { pedido, setPedido } = React.useContext(ContextPedido);
  const { solicitacao, setSolicitacao } = React.useContext(ContextSolicitacao);

  console.log(solicitacao);

  return (
      <div>
        <h1>Solicitacao</h1>
        {props.children}
      </div>
  )
}
