/* eslint-disable */
import React from "react";

import Bottom from "components/Bottom";
import HeaderApp from "components/Header/HeaderApp";
import "./styles.scss"

export default function Layout(props) {
  return (
    <>
      <HeaderApp />
      <div className="content-wrapper-default">
        {props.children}
      </div>
      <Bottom />
    </>
  )
}
