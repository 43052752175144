import React from 'react';

import TitlePages from 'components/_base/TitlePages';

import Layout from 'components/Layout';
import ContentHistoricoPedidos from './ContentHistoricoPedidos';

const App = () => {
  return (
    <>
      <Layout>
        <TitlePages title="Histórico de Pedidos" />
        <ContentHistoricoPedidos />
      </Layout>
    </>
  );
};

export default App;
