/* eslint-disable */
import React from 'react';
import TitlePages from 'components/_base/TitlePages';
import axios from 'axios';

import { ContextPedido } from './context-pedido';
import { ContextEntities } from './context-entities-pedido';

const tipoEntregasNome = {
  lojaLoja: 'LojaLoja',
  centroDistribuicao: 'CentroDistribuicao',
  outro: 'Outro',
  digital: 'Digital',
}

// {
//   materialId: int,
//   imprimir: true|false,
//   entregaTipoSigla: string|undefined
// }



export default function EntregaFisica(props) {
  const { pedido, refreshPedido, setPedido } = React.useContext(ContextPedido);
  const { tipoEntregas, loadTipoEntregas, enderecoCentroDistribuicao } = React.useContext(ContextEntities);

  React.useEffect(() => {
    loadTipoEntregas();
    //refreshPedido();
  }, [])

  console.log('pedido: ', pedido);

  async function buscarCep() {
    //fazer a chamada para alguma api
    const response = await axios.get(`https://brasilapi.com.br/api/cep/v1/${pedido.enderecoOutro.cep}`)
      .catch(() => { });

    //setar o enderecoOutro
    pedido.enderecoOutro = {
      ...pedido.enderecoOutro,
      logradouro: response?.data?.street || "",
      bairro: response?.data?.neighborhood || "",
      cidade: response?.data?.city || "",
      uf: response?.data?.state || ""
    };

    pedido.enderecoOutro.logradouro = response?.data?.street || "";
    pedido.enderecoOutro.bairro = response?.data?.neighborhood || "";
    pedido.enderecoOutro.cidade = response?.data?.city || "";
    pedido.enderecoOutro.uf = response?.data?.state || "";

    setPedido({...pedido});
  }

  let solicitacoesComPossuiProducao = pedido.solicitacoes.filter(s => s.materialComunicacao.possuiProducao === true)

  let tipoEntregasFisica = tipoEntregas.filter(t => t.entregaFisica === true)



  return (
    <>
      <TitlePages title="Entrega de Materiais Gráficos" />

      <button type="button" className={`btn text-white m-2 bg-non-selected`}>botao cinza</button>
      <button type="button" className={`btn text-white m-2 bg-selected`}>botao azul</button>

      <div>
        {
          solicitacoesComPossuiProducao.filter(s => s.imprimir === true)
            ? solicitacoesComPossuiProducao.map(s => <button key={s.materialComunicacaoId} type='button' className={`btn text-white m-2 ${s.imprimir ? "bg-selected" : "bg-non-selected"}`}
              onClick={() => {
                s.imprimir = !s.imprimir
                refreshPedido()
              }}>{s.materialComunicacao.nome}</button>)
            : null
        }
      </div>

      <div>
        {
          solicitacoesComPossuiProducao.find(s => s.imprimir === true)
            ? tipoEntregasFisica.map(e => <button key={e.entregaTipoId} type='button' className={`btn text-white m-2 ${pedido.entregaFisicaId === e.entregaTipoId ? "bg-selected" : "bg-non-selected"}`}
              onClick={() => {
                pedido.entregaFisicaId = e.entregaTipoId;
                refreshPedido()
                //setPedido({...pedido, entregaFisicaId: e.entregaTipoId})
              }}>{e.nome}</button>)
            : null
        }
      </div>
      <div>
        {
          tipoEntregas.find(t => t.entregaTipoId === pedido.entregaFisicaId && t.nome === tipoEntregasNome.centroDistribuicao)
            && pedido.solicitacoes.findIndex(p => p.imprimir === true) !== -1
            ?
            <>
              <h4>Centro de Distribuição</h4>
              <input type="text" value={enderecoCentroDistribuicao?.cep || ""} disabled={true} />
              <input type="text" value={enderecoCentroDistribuicao?.endereco || ""} disabled={true} />
              <input type="text" value={enderecoCentroDistribuicao?.numero || ""} disabled={true} />

              <input type="text" value={pedido.entregaFisicaResponsavel?.nome || ""}
                onChange={(e) => {
                  pedido.entregaFisicaResponsavel.nome = e.target.value;
                  refreshPedido();
                }} />

              <input type="text" value={pedido.entregaFisicaResponsavel?.telefone || ""}
                onChange={(e) => {
                  pedido.entregaFisicaResponsavel.telefone = e.target.value;
                  refreshPedido();
                }} />

              <input type="text" value={pedido.entregaFisicaResponsavel?.email || ""}
                onChange={(e) => {
                  pedido.entregaFisicaResponsavel.email = e.target.value;
                  refreshPedido();
                }} />

            </>

            : null
        }
      </div>
      <div>
        {
          tipoEntregas.find(t => t.entregaTipoId === pedido.entregaFisicaId && t.nome === tipoEntregasNome.outro)
            && pedido.solicitacoes.findIndex(p => p.imprimir === true) !== -1
            ?
            <>
              <h4>Outro</h4>

              <label>Cep</label>
              <input type="text" value={pedido.enderecoOutro.cep || ""}
                onChange={(e) => {
                  pedido.enderecoOutro.cep = e.target.value;
                  refreshPedido();
                }} />
              <button onClick={() => {
                buscarCep()
              }}>Busca</button>

              <label>Logradouro</label>
              <input type="text" value={pedido.enderecoOutro.logradouro || ""}
                onChange={(e) => {
                  pedido.enderecoOutro.logradouro = e.target.value;
                  refreshPedido();
                }} />

              <label>Cidade</label>
              <input type="text" value={pedido.enderecoOutro.cidade || ""}
                onChange={(e) => {
                  pedido.enderecoOutro.cidade = e.target.value;
                  refreshPedido();
                }} />

              <label>UF</label>
              <input type="text" value={pedido.enderecoOutro.uf || ""}
                onChange={(e) => {
                  pedido.enderecoOutro.uf = e.target.value;
                  refreshPedido();
                }} />

              <label>Nome</label>
              <input type="text" value={pedido.entregaFisicaResponsavel?.nome || ""}
                onChange={(e) => {
                  pedido.entregaFisicaResponsavel.nome = e.target.value;
                  refreshPedido();
                }} />

              <label>Telefone</label>
              <input type="text" value={pedido.entregaFisicaResponsavel?.telefone || ""}
                onChange={(e) => {
                  pedido.entregaFisicaResponsavel.telefone = e.target.value;
                  refreshPedido();
                }} />

              <label>Email</label>
              <input type="text" value={pedido.entregaFisicaResponsavel?.email || ""}
                onChange={(e) => {
                  pedido.entregaFisicaResponsavel.email = e.target.value;
                  refreshPedido();
                }} />
            </>

            : null
        }

        {/* <input type="text" value={pedido.enderecoOutro?.logradouro || ""}
          onChange={(e) => {
            pedido.enderecoOutro.logradouro = e.target.value;
            refreshPedido();
          }} /> */}


        {/*   <>
              <h4>Outro</h4>
              <input type="text" value={enderecoOutro.cep}
                onChange={(e) => setEnderecoOutro({ ...enderecoOutro, cep: e.target.value })} />
              <button onClick={() => {
                buscarCep()
              }}>Busca</button>



              <input type="text" value={enderecoOutro.bairro}
                onChange={(e) => setEnderecoOutro({ ...enderecoOutro, bairro: e.target.value })} />

              <input type="text" value={enderecoOutro.cidade}
                onChange={(e) => setEnderecoOutro({ ...enderecoOutro, cidade: e.target.value })} />

              <input type="text" value={enderecoOutro.uf}
                onChange={(e) => setEnderecoOutro({ ...enderecoOutro, uf: e.target.value })} />

              <input type="text" value={enderecoOutro.responsavelNome}
                onChange={(e) => setEnderecoOutro({ ...enderecoOutro, responsavelNome: e.target.value })} />
            </>
            : null
        }*/}
      </div>


    </>
  )
}
