/* eslint-disable */
import React from 'react';
import { useHistory } from 'react-router-dom';

export const ContextPedido = React.createContext({});

export const ContextPedidoProvider = props => {
  const history = useHistory()

  const [pedido, setPedido] = React.useState({
    solicitacoes: [],
    enderecoOutro: {},
    enderecoCentroDistribuicao: {},
    entregaDigital: {
      nome: "",
      telefone: "",
      email: ""
    },
    entregaFisicaResponsavel: {
      nome: "",
      telefone: "",
      email: ""
    }
  });

  function refreshPedido() {
    setPedido({
      ...pedido,
      //solicitacoes: [...(pedido?.solicitacoes || [])]
      //solicitacoes: [...(pedido?.solicitacoes || []).map(s => ({...s}))]
    })
  }

  // React.useEffect(() => {
  //   checkUser();
  // }, [])

  // async function checkUser() {

  // }

  return (
    <ContextPedido.Provider value={{
      pedido,
      setPedido,
      refreshPedido,
      history
    }}>
      {props.children}
    </ContextPedido.Provider>
  );
};
