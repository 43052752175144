/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import ModalImage from "react-modal-image";
import './styles.scss';
import api from 'api';
import { Context } from 'context/AuthContext';
import { GET_LIST_SOLICITACAO_ARQUIVO } from 'services/SolicitacaoFluxo';

const SolicitacaoArquivos = (props) => {
  const { showLoader } = useContext(Context);

  let solicitacaoId = props.solicitacaoId || 0;
  const [solicitacaoArquivos, setSolicitacaoArquivos] = useState([]);

  useEffect(() => {
    if (solicitacaoId === 0) return;

    (async () => {
      showLoader(true);

      const responseArquivos = await GET_LIST_SOLICITACAO_ARQUIVO(solicitacaoId);
      setSolicitacaoArquivos(responseArquivos.value);

      showLoader(false);
    })();
  }, [solicitacaoId]);

  const renderArquivo = (arquivo, index) => {
    let extensaoArquivo = arquivo.arquivoUrl
      .match(/\.(mp4|gif|jpeg|jpg|png)/gi)
      ?.shift();
    return (
      <div key={index} className="col-md-6 d-flex justify-content-center">
        {
          !extensaoArquivo ? (
            <div className="img-fluid">
              <a href={arquivo.arquivoUrl} className="btn btn-link" target="_blank" rel="noopener noreferrer">Download</a>
            </div>
          )
            : (
              extensaoArquivo === '.mp4' ? (
                <video width="320" height="240" controls>
                  <source src={arquivo.arquivoUrl} type="video/mp4" />
                  Seu navegador não suporta reprodução de video.
                </video>
              ) : (
                <div className="img-fluid">
                  <ModalImage small={arquivo.arquivoUrl} large={arquivo.arquivoUrl} />
                </div>
              )
            )
        }
      </div>
    );
  };
  const renderLink = (arquivo, index) => {
    return (
      <div key={index} className="col-md-6 justify-content-center">
        Link:{' '}
        <a href={arquivo.arquivoUrl} target="_blank">
          {arquivo.arquivoUrl}
        </a>
      </div>
    );
  };

  function renderSolicitacaoArquivos() {
    if (!solicitacaoArquivos || !solicitacaoArquivos.length === 0) return null;

    return (
      <>
        {solicitacaoArquivos.map((categoriaArquivo, index1) => {
          return (
            <div key={index1}>
              <h4 className="text-center">{categoriaArquivo.categoria}</h4>
              <div className="row justify-content-md-center mb-3">
                {categoriaArquivo.arquivos.map((arquivo, index2) => {
                  return arquivo.tipo === 'AQV'
                    ? renderArquivo(arquivo, index2)
                    : arquivo.tipo === 'LNK' && renderLink(arquivo, index2);
                })}
              </div>
            </div>
          );
        })}
      </>
    );
  }

  return <div className="solicitacao-fluxo-arquivos">{renderSolicitacaoArquivos()}</div>;
};

export default SolicitacaoArquivos;
