/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FaAngleRight } from 'react-icons/fa';
import Select from 'react-select';

import { passos } from '../../../_constants';

const Layout = (props) => {
  let entities = props.entities;
  let solicitacao = props.solicitacao;
  let avancar = props.avancar;
  let renderErrors = props.renderErrors;
  let errors = (solicitacao.errors || []).filter(
    (error) => error.passo === passos.passoLayout.nro
  );

  const [categoriaLayoutSelecionado, setCategoriaLayoutSelecionado] = useState();
  const [marcaSelecionada, setMarcaSelecionada] = useState();

  solicitacao.solicitacaoDados = solicitacao.solicitacaoDados || {};

  let layouts = (entities.clienteLayouts.layoutArtes || []).filter(
    (layout) =>
      layout.layoutArteMateriaisComunicacao.findIndex(
        (lm) => lm.materialComunicacaoId === solicitacao.materialComunicacaoId
      ) !== -1
  );

  const [layoutArteId, setLayoutArteId] = useState(
    solicitacao.solicitacaoDados.layoutArteId || 0
  );

  useEffect(() => {
    setLayoutArteId(solicitacao.solicitacaoDados.layoutArteId || 0);
  }, [solicitacao]);

  let selectListCategoriaLayouts = entities.categoriaLayouts.map(x => ({
    label: x.nome,
    value: x.categoriaLayoutId
  }));

  let selectListMarcas = entities.marcas.map(x => ({
    label: x.nome,
    value: x.produtoCategoriaId
  }));

  console.log(categoriaLayoutSelecionado);
  console.log(layouts);

  const renderLayouts = () => {
    if (!layouts.length) return null;

    if (categoriaLayoutSelecionado > 0)
      layouts = layouts.filter(x => x.categoriaLayoutId == categoriaLayoutSelecionado)

    if (marcaSelecionada > 0)
      layouts = layouts.filter(x => x.layoutArteProdutoCategoria.findIndex(y => y.produtoCategoriaId == marcaSelecionada) != -1)

    return (
      <>
        <div className="row mt-3 mb-3">
          <div className="col-lg-6">
            <div className="mb-4">
              <label>Categoria Layout</label>
              <Select
                options={selectListCategoriaLayouts}
                isClearable={true}
                onChange={(item) => setCategoriaLayoutSelecionado(item?.value)}
                value={selectListCategoriaLayouts.find(
                  (item) => item.value === categoriaLayoutSelecionado
                )}
              />
            </div>

            <div className="mb-4">
              <label>Marca</label>
              <Select
                options={selectListMarcas}
                isClearable={true}
                onChange={(item) => setMarcaSelecionada(item?.value)}
                value={selectListMarcas.find(
                  (item) => item.value === marcaSelecionada
                )}
              />
            </div>
          </div>
        </div>
        <div className="row">
          {layouts
            .sort(function (a, b) {
              return a.descricao.localeCompare(b.descricao);
            })
            .map((layout, index) => {
              return (
                <div key={index} className="col-lg-4 mg-3">
                  <label>
                    <input
                      type="radio"
                      checked={layout.layoutArteId === layoutArteId}
                      onChange={() => {
                        solicitacao.solicitacaoDados.layoutArteId =
                          layout.layoutArteId;
                        setLayoutArteId(
                          solicitacao.solicitacaoDados.layoutArteId
                        );
                      }}
                    />{' '}
                    {layout.descricao}
                    <img src={layout.urlImagem} className="img-fluid" />
                  </label>
                </div>
              );
            })}
        </div>
      </>
    );
  };

  return (
    <div>
      {renderErrors(errors)}

      {renderLayouts()}

      <div className="row mt-5">
        <div className="col-lg-12 d-flex flex-row-reverse">
          <button
            className="btn btn-primary btn-navigation btn-navigation-right"
            onClick={() => avancar()}
          >
            <span>AVANÇAR</span>
            <span>
              <FaAngleRight size={26} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Layout;
