/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import TablePagination from 'components/TablePagination';
import CardItemArtes from './CardItemArtes';
import './styles.scss';

const CardArtes = (props) => {
  const [artes, setArtes] = useState([]);

  useEffect(() => {
    if (props.data) {
      setArtes(props.data);
    }
  }, [props.data]);

  return (
    <>
      <div className="card-deck-artes">
        {artes.map((arte, index) => (
          <CardItemArtes
            key={`banco-artes-${index}`}
            arte={arte}
            onClickResumo={props.onClickResumo}
            handleSolicitarNovamente={props.handleSolicitarNovamente}
          />
        ))}
      </div>
      {props.data && (
        <TablePagination
          pageData={props.pageData}
          handlePageChange={props.changePage}
        />
      )}
    </>
  );
};

export default CardArtes;
