/* eslint-disable */
import React from 'react';
import { ContextPedido } from './context-pedido';
import { ContextSolicitacao } from './solicitacao/context-solicitacao';

export default function Solicitacoes(props) {
  const { pedido, setPedido, history } = React.useContext(ContextPedido);
  const { solicitacao } = React.useContext(ContextSolicitacao);

  function changeMaterial(index) {
    history.push(`/pedido-teste/solicitacao/${index}`)
  }

  return (
    <>
      <h1>Solicitacoes - {solicitacao?.materialComunicacao?.nome}</h1>

      <div className="row m-0">
        <div className="col-lg-2">
          {
            pedido.solicitacoes.map((s, index) =>
              <div key={s.materialComunicacaoId}>
                <label>
                  <button className={`btn text-white m-2 ${solicitacao.materialComunicacao.materialComunicacaoId === s.materialComunicacao.materialComunicacaoId ? "bg-selected" : "bg-non-selected"}`}
                    onClick={() => changeMaterial(index)}
                  >{s.materialComunicacao.nome}</button>
                </label>
              </div>
            )
          }
        </div>

        <div className="col-lg-10">
          {props.children}
        </div>
      </div>
    </>
  )
}
