/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';

import { Context } from 'context/AuthContext';

import TitlePages from 'components/_base/TitlePages';

import { GET_LIST_LAYOUT_ARTE_DISPONIVEIS } from 'services/LayoutArte';

import './styles.scss';

const ContentLayoutsDisponiveis = () => {
  const { showLoader } = useContext(Context);

  const [layouts, setLayouts] = useState([]);

  async function getListLayoutsDisponiveis() {
    showLoader(true);
    const data = await GET_LIST_LAYOUT_ARTE_DISPONIVEIS();

    setLayouts(data.value);

    showLoader(false);
  }

  useEffect(() => {
    getListLayoutsDisponiveis();
  }, []);

  return (
    <div className="wrapper-layouts-disponiveis">
      <TitlePages title="Layouts Disponíveis" />
      <div className="row">
        {
          layouts.map((l) => (
            <div className='col-sm-2' >
              <div className='card-layout'>
                <label>{l.descricao}</label>
                <a href={l.urlImagem} target='_blank'>
                  <img className="img-fluid" src={l.urlImagem} alt={l.descricao} />
                </a>
              </div>
            </div>
          ))
        }

      </div>
    </div>
  );
};

export default ContentLayoutsDisponiveis;


