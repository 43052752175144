/* eslint-disable */
import React from 'react';
import { ContextPedido } from './context-pedido';

export default function Resumo(props) {
  const { pedido, setPedido } = React.useContext(ContextPedido);



  return (
    <div>
      <h1>Resumo</h1>

      {props.children}
    </div>
  )
}
