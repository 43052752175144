import React from 'react';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentSolicitacaoFluxoRevisao from './ContentSolicitacaoFluxoRevisao';

const SolicitacaoFluxoRevisao = () => {
  return (
    <>
      <HeaderApp />
      <ContentSolicitacaoFluxoRevisao />
      <Bottom />
    </>
  );
};

export default SolicitacaoFluxoRevisao;
