/* eslint-disable */
import React from 'react';

import { ContextPedido, ContextPedidoProvider } from './context-pedido';
import { ContextSolicitacaoProvider } from './solicitacao/context-solicitacao';
import { ContextEntitiesSolicitacaoProvider } from './solicitacao/context-entities-solicitacao';
import Solicitacoes from './_solicitacoes';
import Solicitacao from './solicitacao/solicitacao';
import Layout from './solicitacao/_layout';
import Produtos from './solicitacao/_produtos';
import Dados from './solicitacao/_dados';
import Resumo from './_resumo';
import ResumoSolicitacao from './solicitacao/_resumo';
import Cliente from './_cliente';
import Materiais from './_materiais';
import EntregaFisica from './_entrega-fisica';
import EntregaDigital from './_entrega-digital';

import './styles.scss';
import { ContextSolicitacoesProvider } from './context-solicitacoes';

const pedidoTabs = {
  cliente: 'cliente',
  materiais: 'materiais',
  entrega: 'entrega',
  entregaFisica: 'entrega-fisica',
  entregaDigital: 'entrega-digital',
  solicitacao: 'solicitacao',
  resumo: 'resumo'
}

const solicitacaoTabs = {
  layout: 'layout',
  produtos: 'produtos',
  dados: 'dados'
}

export default function Pedido(props) {
  const [pedidoTab, setPedidoTab] = React.useState({});

  const { pedido, refreshPedido, history } = React.useContext(ContextPedido);

  // Redireciona para a tab de acordo com a mudança na url
  React.useEffect(() => {
    return history.listen((location) => {
      // let tab = location.pathname.split('/')[3];
      // setActiveTab(tab);

      let pathSplit = location.pathname.split('/').filter(x => x.length > 0);
      let tabName = pathSplit[1];
      let solicitacaoIndex = pathSplit.length > 2 ? parseInt(pathSplit[2]) || 0 : 0;
      let solicitacaoTab = pathSplit.length > 3 ? pathSplit[3] : solicitacaoTabs.layout;

      setPedidoTab({
        name: tabName,
        solicitacaoIndex: solicitacaoIndex,
        solicitacaoTab: {
          name: solicitacaoTab
        }
      })

    })

  }, [history]);

  // let pedidoTab = {
  //   name: 'entrega'
  // };

  // let pedidoTab = {
  //   name: 'solicitacao',
  //   solicitacaoIndex: 0,
  //   solicitacaoTab: {
  //     name: 'layout'
  //   }
  // }

  function salvar() {
    //addPedido(pedido);
    console.log(pedido);
  }

  return (
    <>
      <button type="button" onClick={() => {
        setPedidoTab({
          name: pedidoTabs.cliente
        })
      }}>setTabCliente</button>

      <button type="button" onClick={() => {
        setPedidoTab({
          name: pedidoTabs.materiais
        })
      }}>setTabMateriais</button>

      <button type="button" onClick={() => {
        setPedidoTab({
          name: pedidoTabs.entregaFisica
        })
      }}>setTabEntregaFisica</button>

      <button type="button" onClick={() => {
        setPedidoTab({
          name: pedidoTabs.entregaDigital
        })
      }}>setTabEntregaDigital</button>

      <button type="button" onClick={() => {
        setPedidoTab({
          name: 'solicitacao',
          solicitacaoIndex: 0,
          solicitacaoTab: 'layout'
        })
      }}>setTabSolicitacao0</button>

      <button type="button" onClick={() => {
        setPedidoTab({
          name: 'solicitacao',
          solicitacaoIndex: 1,
          solicitacaoTab: {
            name: 'layout'
          }
        })
      }}>setTabSolicitacao1</button>

      <button type="button" onClick={() => {
        setPedidoTab({
          name: 'solicitacao',
          solicitacaoIndex: 2,
          solicitacaoTab: {
            name: 'layout'
          }
        })
      }}>setTabSolicitacao2</button>

      <button type="button" onClick={() => {
        history.push(`/pedido-teste/${pedidoTabs.solicitacao}/${pedidoTab.solicitacaoIndex || "0"}/${solicitacaoTabs.produtos}`)
      }}>setTabProdutos</button>

      <button type="button" onClick={() => {
        history.push(`/pedido-teste/${pedidoTabs.solicitacao}/${pedidoTab.solicitacaoIndex || "0"}/${solicitacaoTabs.dados}`)
      }}>setTabDados</button>

      <button type="button" onClick={() => {
        setPedidoTab({
          name: 'resumo'
        })
      }}>setTabResumo</button>

      <button type="button" onClick={refreshPedido}>refreshPedido</button>

      <div className="pedido-teste">
        {pedidoTab?.name === pedidoTabs.cliente ? <Cliente /> : null}

        {pedidoTab?.name === pedidoTabs.materiais ? <Materiais /> : null}

        {
          pedidoTab?.name === pedidoTabs.entrega
            ? pedido?.solicitacoes?.findIndex(s => s.materialComunicacao?.possuiProducao) !== -1
              ? <EntregaFisica />
              : <EntregaDigital />
            : null
        }

        {pedidoTab?.name === pedidoTabs.entregaFisica ? <EntregaFisica /> : null}

        {pedidoTab?.name === pedidoTabs.entregaDigital ? <EntregaDigital /> : null}

        {
          pedido?.solicitacoes.map((solicitacao, index) => (
            <ContextSolicitacaoProvider key={solicitacao.materialComunicacaoId} solicitacao={solicitacao}>
              <ContextEntitiesSolicitacaoProvider>
                {
                  pedidoTab?.name === pedidoTabs.solicitacao && pedidoTab.solicitacaoIndex === index
                    ? (
                      <Solicitacoes>
                        <Solicitacao>
                          {pedidoTab.solicitacaoTab.name === solicitacaoTabs.layout ? <Layout /> : null}
                          {pedidoTab.solicitacaoTab.name === solicitacaoTabs.produtos ? <Produtos /> : null}
                          {pedidoTab.solicitacaoTab.name === solicitacaoTabs.dados ? <Dados /> : null}
                        </Solicitacao>
                      </Solicitacoes>
                    )
                    : null
                }
              </ContextEntitiesSolicitacaoProvider>
            </ContextSolicitacaoProvider>
          ))
        }

        {
          pedidoTab?.name === pedidoTabs.resumo
            ? (
              <Resumo>
                {
                  pedido.solicitacoes.map((solicitacao) => (
                    <ResumoSolicitacao key={solicitacao.materialComunicacaoId} solicitacao={solicitacao} />
                  ))
                }
              </Resumo>
            )
            : null
        }
        <hr />
        <button type="button" className="btn btn-primary" onClick={salvar}>Enviar pedido</button>
      </div>
    </>
  )
}
