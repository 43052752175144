import React from 'react';

import Layout from 'components/Layout';
import TitlePages from 'components/_base/TitlePages';

import ContentQuery from './ContentQuery';

const App = () => {
  return (
    <Layout>
      <TitlePages title="Consulta" />
      <ContentQuery />
    </Layout>
  );
};

export default App;
