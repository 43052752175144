/* eslint-disable */
import React from 'react';
import { ContextPedido } from './context-pedido';
import TitlePages from 'components/_base/TitlePages';
import { FaSearch } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';

import { Context } from 'context/AuthContext';
import { GET_LIST_LOJA } from 'services/Solicitacao';

export default function Cliente(props) {
  const { pedido, setPedido, refreshPedido } = React.useContext(ContextPedido);
  const { showLoader } = React.useContext(Context)
  const [codigoCNPJ, setCodigoCNPJ] = React.useState("");
  const [razaoSocial, setRazaoSocial] = React.useState("");
  const [resultado, setResultado] = React.useState({
    termo: undefined,
    lojas: undefined
  });
  const [showModal, setShowModal] = React.useState(false);

  async function getLojas(termo) {
    setShowModal(true);

    //se o termo for igual nao refazer a busca
    if (termo === resultado.termo)
      return;

    //fazer a busca
    showLoader(true);
    const data = await GET_LIST_LOJA({ codigoCnpjRazaoSocial: termo });
    showLoader(false)


    setResultado({
      termo,
      lojas: data.value
    })
    //--
  }

  function setLoja(loja) {
    pedido.lojaId = loja.lojaId;
    pedido.loja = loja;
    refreshPedido();
  }

  return (
    <>
      <TitlePages title="Escolha o Cliente " />
      <div className="d-flex justify-content-center">
        <div className="col-lg-3">
          <h4 className="text-center">Código ou CNPJ do Cliente</h4>
          <div className="input-with-icon justify-content-end">
            <input type="text" value={codigoCNPJ} onChange={(e) => setCodigoCNPJ(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && getLojas(codigoCNPJ)} />
            <div className="icon" onClick={() => getLojas(codigoCNPJ)}>
              <FaSearch size={22} />
            </div>
          </div>
        </div>

        <div className="col-lg-1 align-self-center text-center">
          <h4 className="text-center m-3">OU</h4>
        </div>

        <div className="col-lg-3 align-self-end">
          <h4 className="text-center">Razão Social</h4>
          <div className="input-with-icon">
            <input type="text" value={razaoSocial} onChange={(e) => setRazaoSocial(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && getLojas(razaoSocial)} />
            <div className="icon" onClick={() => getLojas(razaoSocial)}>
              <FaSearch size={22} />
            </div>
          </div>
        </div>
      </div>

      <hr />

      {
        pedido?.loja?.lojaId > 0
          ? (
            <div className="d-flex justify-content-center">
              <div className="col-lg-7">
                <div>
                  <label className="font-weight-bold mr-2">Código: </label>
                  <span>{pedido?.loja?.codigo ? pedido?.loja?.codigo : '-'}</span>
                </div>

                <div>
                  <label className="font-weight-bold mr-2">Razão Social: </label>
                  <span>{pedido?.loja?.razaoSocial ? pedido?.loja?.razaoSocial : '-'}</span>
                </div>

                <div>
                  <label className="font-weight-bold mr-2">CNPJ: </label>
                  <span>{pedido?.loja?.cnpj ? pedido?.loja?.cnpj : '-'}</span>
                </div>
              </div>
            </div>
          )
          : null
      }

      <Modal show={showModal} className="modal-busca-loja">
        <Modal.Header closeButton className="header">
          <Modal.Title>Clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            resultado.lojas === undefined //ainda nao foi feito nenhuma busca
              ? null
              : resultado.lojas.length === 0
                ? <label>Nenhum resultado encontrado</label>
                : (
                  <table className="table table-striped">
                    <thead>
                      <tr className="font-weight-bold">
                        <td>Código</td>
                        <td>Razão Social</td>
                        <td>CNPJ</td>
                        <td>Endereço</td>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        resultado.lojas.map(l => (
                          <tr key={l.lojaId}
                            className={`tr-selecao-loja ${l.lojaId === pedido?.loja?.lojaId ? "tr-selecionado" : null}`}
                            onClick={() => {
                              setLoja(l);
                              setShowModal(false)
                            }}>
                            <td>{l.codigo}</td>
                            <td>{l.razaoSocial}</td>
                            <td>{l.cnpj}</td>
                            <td>
                              {(l.logradouro?.length > 0 ? l.logradouro + ", " : "")}
                              {(l.numero?.length > 0 ? l.numero + " - " : "")}
                              {l.cidade.nome} - {l.cidade.uf}
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>

                )
          }
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>


    </>
  )


}
