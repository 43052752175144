/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { FaAngleRight, FaHome, FaSearch } from 'react-icons/fa';

import { GET_URL_LOGO_PADRAO, GET_LIST_LOJA } from 'services/Solicitacao';
import { UPLOAD_LOGO } from 'services/Solicitacao';
import RequestMaterialsActions from 'store/modules/request-materials/actions';
import TitlePages from 'components/_base/TitlePages';
import InputFileCustom from 'components/_base/InputFile';

import { tabs, passos } from '../_constants';
import './styles.scss';

const Cliente = (props) => {
  let { setLoader, updateRascunho, model, renderErrors, setActiveTab } = props;
  let errors = (model.errors || []).filter(
    (error) => error.passo === passos.passoCliente.nro
  );

  model.loja = model.loja || {};

  const dispatch = useDispatch();

  const [cliente, setCliente] = useState(model.loja || {});
  const [clientSelected, setClientSelected] = useState(null);
  const [clientsList, setClientsList] = useState([]);
  const [logoPadrao, setLogoPadrao] = useState('');
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [withBrand, setWithBrand] = useState(true);

  const updateCliente = (obj) => {
    model.loja = obj;
    model.lojaId = obj.lojaId || 0;
    setCliente(obj);
  };

  useEffect(() => {
    setCliente(model.loja || {});
  }, [model]);

  useEffect(() => {
    (async () => {
      setLoader(true);
      const resultLogoPadrao = await GET_URL_LOGO_PADRAO();

      if (resultLogoPadrao.value) setLogoPadrao(resultLogoPadrao.value);

      setLoader(false);
    })();
  }, [false]);

  const searchByCodeCNPJ = (event) => {
    setSearch(event.target.value);
  };

  const searchByName = (event) => {
    setSearch(event.target.value);
  };

  const onSearch = async () => {
    setLoader(true);
    const data = await GET_LIST_LOJA({ codigoCnpjRazaoSocial: search });
    if (data.value.length === 1) {
      updateCliente(data.value[0]);
    } else {
      setClientsList(data.value);
      setShowModal(true);
    }

    setLoader(false);
  };

  const selectClient = (index) => {
    setClientSelected(index);
    updateCliente(clientsList[index]);

    const action = RequestMaterialsActions.setCustomer(clientsList[index]);
    dispatch(action);

    setWithBrand(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const setSettingsBrand = (event) => {
    setWithBrand(event.target.value === 'true');
  };

  const uploadLogo = async (event) => {
    event.preventDefault();

    setLoader(true);
    const formData = new FormData();

    formData.append('lojaId', cliente.lojaId);
    formData.append('file', event.target.files[0]);

    const data = await UPLOAD_LOGO(formData);

    updateCliente({ ...cliente, urlLogo: data.value });

    setLoader(false);
  };

  return (
    <div className="wrapper-content-pedido-cliente">
      <TitlePages title="Escolha o Cliente" />
      <div className="content-pedido-cliente">
        {renderErrors(errors)}

        <div className="content-cliente-search">
          <div id="busca" className=" busca">
            <div className="col-md-5">
              <label htmlFor="">Código ou CNPJ do Cliente</label>
              <div className="input-with-icon">
                <input type="text" onChange={searchByCodeCNPJ} />
                <div className="icon" onClick={onSearch}>
                  <FaSearch size={22} />
                </div>
              </div>
            </div>

            <div className="col-lg-2 align-self-center text-center">
              <label className="m-0">OU</label>
            </div>

            <div className="col-md-5">
              <label htmlFor="">Razão Social</label>
              <div className="input-with-icon">
                <input type="text" onChange={searchByName} />
                <div className="icon" onClick={onSearch}>
                  <FaSearch size={22} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={hideModal} className="modal-busca-loja">
          <Modal.Header closeButton className="header">
            <Modal.Title>Clientes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Razão Social</th>
                  <th>CNPJ</th>
                  <th>Endereço</th>
                </tr>
              </thead>
              <tbody>
                {clientsList.map((item, index) => {
                  return (
                    <tr
                      className={
                        clientSelected === index
                          ? 'tr-selecao-loja tr-selecionado'
                          : 'tr-selecao-loja'
                      }
                      key={index}
                      onClick={() => selectClient(index)}
                      style={{
                        backgroundColor:
                          clientSelected === index
                            ? 'rgba(40, 109, 184,.5)'
                            : 'inherit',
                      }}
                    >
                      <td>{item.razaoSocial}</td>
                      <td>{item.cnpj}</td>
                      <td>
                        {(item.logradouro?.length > 0 ? item.logradouro + ", " : "")}
                        {(item.numero?.length > 0 ? item.numero + " - " : "")}
                        {item.cidade.nome} - {item.cidade.uf}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button
              variant="primary"
              onClick={hideModal}
              className="button-close"
            >
              CONCLUIR
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="display-client">
          <div>
            <div>
              <label htmlFor="">Código: </label>
              <span>{cliente.codigo ? cliente.codigo : '-'}</span>
            </div>

            <div>
              <label htmlFor="">Razão Social: </label>
              <span>{cliente.razaoSocial ? cliente.razaoSocial : '-'}</span>
            </div>

            <div>
              <label htmlFor="">CNPJ: </label>
              <span>{cliente.cnpj ? cliente.cnpj : '-'}</span>
            </div>

            {/* <div>
              <label htmlFor="">IE: </label>
              <span>{cliente.ie ? cliente.ie : ''}</span>
            </div>

            <div>
              <label htmlFor="">Regional: </label>
              <span>
                {cliente.regional?.nome ? cliente.regional?.nome : ''}
              </span>
            </div>

            <div>
              <label htmlFor="">Canal: </label>
              <span>{cliente.canal?.nome ? cliente.canal?.nome : ''}</span>
            </div>

            <div>
              <label htmlFor="">Rede: </label>
              <span>{cliente.rede?.nome ? cliente.rede?.nome : ''}</span>
            </div>

            <div>
              <label htmlFor="">CEP: </label>
              <span>{cliente.cep ? cliente.cep : '-'}</span>
            </div>

            <div>
              <label htmlFor="">Logradouro: </label>
              <span>{cliente.logradouro ? cliente.logradouro : ''}</span>
            </div>

            <div>
              <label htmlFor="">Número: </label>
              <span>{cliente.numero ? cliente.numero : ''}</span>
            </div>

            <div>
              <label htmlFor="">Bairro: </label>
              <span>{cliente.bairro ? cliente.bairro : ''}</span>
            </div>

            <div>
              <label htmlFor="">Cidade: </label>
              <span>
                {cliente.cidade && cliente.cidade.nome
                  ? cliente.cidade.nome + ' - ' + cliente.cidade.uf
                  : ''}
              </span>
            </div> */}
          </div>
          <div className="set-settings-brand">
            <div
              style={{ opacity: cliente.lojaId ? 1 : 0 }}
              className="text-right"
            >
              <input
                type="radio"
                id="set-brand"
                name="with-brand"
                onChange={setSettingsBrand}
                value={true}
                checked={withBrand}
              />
              <label htmlFor="set-brand">Com Logo</label>

              <input
                type="radio"
                id="set-no-brand"
                name="with-brand"
                onChange={setSettingsBrand}
                value={false}
                checked={!withBrand}
              />
              <label htmlFor="set-no-brand">Sem Logo</label>

              {withBrand ? (
                <div>
                  {cliente.urlLogo && (
                    <img
                      className="img-logo"
                      src={cliente.urlLogo ? cliente.urlLogo : ''}
                      alt=""
                    />
                  )}
                  <div style={{ marginTop: '20px' }}></div>
                  <InputFileCustom
                    name="Logo"
                    onChange={uploadLogo}
                  ></InputFileCustom>
                </div>
              ) : (
                <div>
                  <div className="msg-logo-padrao text-center">
                    Seus materiais serão impressos com o logo padrão.
                  </div>
                  <img className="img-logo" src={logoPadrao} alt="" />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex justify-content-between">
            <div>
              <Link to="/">
                <button className="btn btn-primary btn-navigation">
                  <span>
                    <FaHome size={26} />
                  </span>
                  <span>PÁGINA INICIAL</span>
                </button>
              </Link>
            </div>
            <div>
              <button
                className={`btn btn-primary btn-navigation btn-navigation-right ${cliente.lojaId > 0 ? '' : 'btn-disabled'
                  }`}
                onClick={() => {
                  if (cliente.lojaId > 0) {
                    updateRascunho();

                    if (model.pedidoDeCampanha)
                      setActiveTab(tabs.tabLayoutCampanha);
                    else setActiveTab(tabs.tabMateriais);
                  }
                }}
              >
                <span>AVANÇAR</span>
                <span>
                  <FaAngleRight size={26} />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cliente;
