/* eslint-disable */
import React from 'react';
import { ContextPedido } from '../context-pedido';
import { ContextEntitiesSolicitacao } from './context-entities-solicitacao';
import { ContextSolicitacao } from './context-solicitacao';

export default function Layout(props) {
  const { pedido, refreshPedido } = React.useContext(ContextPedido);
  const { solicitacao, setSolicitacao } = React.useContext(ContextSolicitacao);
  const { layouts } = React.useContext(ContextEntitiesSolicitacao);

  console.log('pedido', pedido);
  console.log('solicitacao', solicitacao);

  return (
    <>
      <h1>Layout</h1>

      <div className="row">
        {
          layouts.map(l => (
            <div key={l.layoutArteId} className="col-lg-2">
              <label>
                <input type="radio" value={l.descricao}
                  checked={solicitacao?.solicitacaoDados?.layoutArteId === l.layoutArteId}
                  onChange={() => {
                    solicitacao.solicitacaoDados.layoutArteId = l.layoutArteId;
                    refreshPedido();
                  }}
                /><span className="ml-2">{l.descricao}</span>

                <img src={l.urlImagem} className="img-fluid" />
              </label>
            </div>
          ))
        }
      </div>


    </>
  )
}
