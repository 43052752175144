import React from 'react';

import Header from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';

import ContentLayoutsDisponiveis from './ContentLayoutsDisponiveis';

const LayoutsDisponiveis = () => {
  return (
    <>
      <Header />
      <ContentLayoutsDisponiveis path="layouts-disponiveis" />
      <Bottom />
    </>
  );
};

export default LayoutsDisponiveis;
