/* eslint-disable */
import React from 'react';

export const ContextSolicitacao = React.createContext({});

export const ContextSolicitacaoProvider = ({solicitacao, children}) => {
  const [_solicitacao, setSolicitacao] = React.useState(solicitacao);



  // async function checkUser() {

  // }

  return (
    <ContextSolicitacao.Provider value={{
      solicitacao: _solicitacao,
      setSolicitacao
    }}>
      {children}
    </ContextSolicitacao.Provider>
  );
};
