import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { GET_CHAMADO_USER_ID } from 'services/Chamado';

import './styles.scss';

const NotificacaoChamados = () => {
  const userAuth = JSON.parse(localStorage.getItem('encartes_dpa_site'));
  const [contadorChamado, setContadorChamado] = useState(0);

  useEffect(() => {
    (async () => {
      if (userAuth) {
        const data = await GET_CHAMADO_USER_ID(userAuth?.userId);

        // Numero de chamados que não foi finalizado
        const chamadosAbertos = data.value.filter(
          (item) => item.dataFinalizacao === null
        );
        setContadorChamado(chamadosAbertos.length);
      }
    })();
  }, []);

  return (
    <>
      {contadorChamado > 0 && (
        <a href="/acompanhar-chamado" className="notifications cursor-pointer">
          <FontAwesomeIcon icon={faBullhorn} size="2x" />
          {contadorChamado > 0 ? (
            <span id="chamados">{contadorChamado}</span>
          ) : (
            ''
          )}
        </a>
      )}
    </>
  );
};

export default NotificacaoChamados;
