/* eslint-disable */
import React from 'react';
import { ContextPedidoProvider } from './context-pedido';
import { ContextEntitiesProvider } from './context-entities-pedido';
import Pedido from './pedido';
import Layout from 'components/Layout';

export default function Index(props) {
  return (
    <Layout>
      <ContextPedidoProvider>
        <ContextEntitiesProvider>
          <Pedido />
        </ContextEntitiesProvider>
      </ContextPedidoProvider>
    </Layout>
  )
}
