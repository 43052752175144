import React from 'react';

import Layout from 'components/Layout';
import ContentAtendimento from './ContentAtendimento';

const Atendimento = () => {
  return (
    <Layout>
      <ContentAtendimento />
    </Layout>
  );
};

export default Atendimento;
