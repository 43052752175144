/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState, useContext } from 'react';

import { GET_PEDIDO_TIPO_CAMPANHA } from 'services/Pedido';
import { Context } from 'context/AuthContext';
import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentPedido from '../PedidoMateriais/ContentPedido';

const PedidoCampanha = () => {
  const { showLoader } = useContext(Context);

  const [pedidoTipo, setPedidoTipo] = useState({});

  useEffect(() => {
    (async () => {
      showLoader(true);

      const result = await GET_PEDIDO_TIPO_CAMPANHA();

      if (result?.value) setPedidoTipo(result.value);

      showLoader(false);
    })();
  }, []);

  if (!pedidoTipo.pedidoTipoId) return <></>;

  return (
    <>
      <HeaderApp />
      <ContentPedido
        pedidoTipo={pedidoTipo}
        pedidoDeCampanha={true}
        baseUrl="/pedido-campanha"
      />
      <Bottom />
    </>
  );
};

export default PedidoCampanha;
