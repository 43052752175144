/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import CurrencyInput from 'react-currency-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import { Context } from 'context/AuthContext';
import TitlePages from 'components/_base/TitlePages';
import ModalSummary from 'components/ModalSummary';
import Historico from '../../Status/ContentSolicitacaoFluxoStatus/Historico';

import {
  GET_REVISAO_MODEL,
  DEFIRNIR_TIPO_ARTE,
} from 'services/SolicitacaoFluxo';

const ContentSolicitacaoFluxoRevisao = () => {
  const { showLoader } = useContext(Context);

  const paramsLocation = new URLSearchParams(useLocation().search);

  const [solicitacaoId] = useState(
    Number.parseInt(paramsLocation.get('solicitacaoId')) || 0
  );
  const [model, setModel] = useState({});
  const [resumoSolicitacaoId, setResumoSolicitacaoId] = useState(0);
  const [resultModel, setResultModel] = useState({});


  useEffect(() => {
    (async () => {
      showLoader(true);

      const responseModel = await GET_REVISAO_MODEL(solicitacaoId);
      setModel(responseModel.value);

      showLoader(false);
    })();
  }, []);

  function salvar(tipoArte) {

    (async () => {

      showLoader(true);

      const response = await DEFIRNIR_TIPO_ARTE(tipoArte);

      showLoader(false);
      //value == true => alterado com sucesso
      if (response.value) {
        setModel({});
      }
      setResultModel(response);
    })();
  }

  function renderResumoButton() {
    if (!model?.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <div className="text-left">
        <button
          className="btn btn-primary"
          onClick={() => {
            setResumoSolicitacaoId(solicitacaoId);
          }}
        >
          Ver Solicitação
        </button>
      </div>
    );
  }

  function renderHistory() {
    if (!model?.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <Historico solicitacaoId={model.solicitacaoId} />
      </>
    );
  }

  function renderButtons() {
    if (!model?.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <div className="text-center mt-4">
        {
          (model?.tipoArtes || []).map(tipoArte => (
            <button type="button" className={`btn ${model.sigla?.length ? 'btn-secondary' : 'btn-primary'} mr-3 ${tipoArte.sigla === model.sigla ? 'btn-success' : ''}`} key={tipoArte.sigla} onClick={() => setModel({ ...model, sigla: tipoArte.sigla })}>Aprovar {tipoArte.descricao}</button>
          ))
        }
      </div>
    );
  }

  function renderForm() {
    if (!model?.solicitacaoId || model.solicitacaoId === 0) return null;

    if (!model.sigla?.length) return null;

    let tipoArte = model.tipoArtes.find(x => x.sigla === model.sigla);

    return (
      <>
        <hr />

        <div className="row justify-content-center mb-4">
          <div className="col-lg-8 text-center">
            <h4>Para definir essa solicitação como <b>{tipoArte.descricao}</b>, confira os dados da solicitação e os valores abaixo fazendo qualquer alteração na solicitação antes de aprovar</h4>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-4">
            <div className="row mb-3">
              <div className="col-lg-12">
                <label className="d-block mb-0">Valor Diagramação</label>
                <CurrencyInput
                  decimalSeparator=","
                  thousandSeparator=""
                  className="form-control"
                  value={tipoArte.valorDiagramacao}
                  onChangeEvent={(e) => {
                    tipoArte.valorDiagramacao = e.target.value;
                    setModel({ ...model })
                  }}
                />
              </div>
            </div>

            {
              model?.imprimir
                ? (
                  <>
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <label className="d-block mb-0">Valor Impressao</label>
                        <CurrencyInput
                          decimalSeparator=","
                          thousandSeparator=""
                          className="form-control"
                          value={tipoArte.valorImpressao}
                          onChangeEvent={(e) => {
                            tipoArte.valorImpressao = e.target.value;
                            setModel({ ...model })
                          }}
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <label className="d-block mb-0">Valor Frete</label>
                        <CurrencyInput
                          decimalSeparator=","
                          thousandSeparator=""
                          className="form-control"
                          value={tipoArte.valorFrete}
                          onChangeEvent={(e) => {
                            tipoArte.valorFrete = e.target.value;
                            setModel({ ...model })
                          }}
                        />
                      </div>
                    </div>
                  </>
                )

                : null
            }

            <div className="text-center">
              <button type="button" className="btn btn-primary" onClick={() => { salvar(tipoArte) }}>Enviar</button>
            </div>
          </div>
        </div>
      </>
    );
  }

  function renderModalSummary() {
    if (resumoSolicitacaoId === 0) return null;

    return (
      <ModalSummary
        solicitacaoId={resumoSolicitacaoId}
        onClose={() => {
          setResumoSolicitacaoId(0);
        }}
      />
    );
  }

  function renderErrors() {
    if (!resultModel.errors || !resultModel.errors.length) return null;

    return (
      <div className="alert alert-danger">
        {resultModel.errors.map((error, index) => {
          return <span key={index}>{error}</span>;
        })}
      </div>
    );
  }

  function renderSuccess() {
    if (!resultModel.message || !resultModel.message.length) return null;

    return (
      <div className="alert alert-success">
        {resultModel.message} <a href="/pendencias">Ir para Pendencias</a>
      </div>
    );
  }

  return (
    <div className="wrapper-content-solicitacaofluxo-aprovar-arte">
      <TitlePages
        title={`Solicitação  ${solicitacaoId} - Aguardando Revisão`}
      />

      <div className="content-solicitacaofluxo">
        {/* {<pre>{JSON.stringify(resultModel, null, 2)}</pre>} */}

        {renderErrors()}

        {renderSuccess()}

        {renderResumoButton()}

        {renderButtons()}

        {renderForm()}

        {renderHistory()}

        {renderModalSummary()}

        {/* {<pre className="text-left">{JSON.stringify(model, null, 2)}</pre>} */}
      </div>
    </div>
  );
};

export default ContentSolicitacaoFluxoRevisao
