/* eslint-disable */
import React from 'react';
import TitlePages from 'components/_base/TitlePages';

import { ContextPedido } from './context-pedido';

export default function EntregaDigital(props) {
  const { pedido, setPedido, refreshPedido } = React.useContext(ContextPedido);

  return (
    <>
      <TitlePages title="Entrega de Materiais Digitais" />

      <div>
        <label>Nome</label>
        <input type='text' placeholder='Nome' value={pedido.entregaDigital.nome}
          onChange={(e) => {
            pedido.entregaDigital.nome = e.target.value;
            refreshPedido();
          }} />
      </div>
      <div>
        <label>Telefone</label>
        <input type='text' placeholder='Telefone' value={pedido.entregaDigital.telefone}
          onChange={(e) => {
            pedido.entregaDigital.telefone = e.target.value
            refreshPedido();
          }} />
      </div>
      <div>
        <label>Email</label>
        <input type='text' placeholder='Email' value={pedido.entregaDigital.email}
          onChange={(e) => {
            pedido.entregaDigital.email = e.target.value
            refreshPedido();
          }} />
      </div>





    </>
  )
}
