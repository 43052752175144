/* eslint-disable */
import React from 'react';
import { ContextPedido } from './context-pedido';

import {
  GET_LIST_MATERIAL_CADASTRO_ITENS_MODEL,
  GET_LIST_MATERIAL_CAMPANHA,
  GET_LIST_EXTENSAO_ARQUIVO,
  GET_LIST_ESTADO,
  GET_LIST_FORMA_PAGAMENTO,
  GET_LIST_MOTIVO_ACAO,
  GET_LOJA_LAYOUT_ARTES,
  GET_LIST_TEMPO_VIDEO,
  GET_LIST_TIPO_ENTREGA,
  GET_LIST_TIPO_MATERIAL,
  GET_LIST_QUANTIDADE_IMPRESSOS,
  GET_LIST_CAMPANHA,
  GET_TAB_PRODUTOS,
  GET_ENDERECO_CENTRO_DISTRIBUICAO,
} from 'services/Solicitacao';

export const ContextEntities = React.createContext({});

export const ContextEntitiesProvider = props => {
  const { pedido } = React.useContext(ContextPedido);

  const [tipoMateriais, setTipoMateriais] = React.useState({ lojaId: 0, lista: [] });
  const [tipoEntregas, setTipoEntregas] = React.useState([]);
  const [enderecoCentroDistribuicao, setEnderecoCentroDistribuicao] = React.useState({ lojaId: 0, endereco: {}});

  React.useEffect(() => {

  }, [])

  React.useEffect(() => {
    loadTipoMateriais();
    loadEnderecoCentroDistribuicao();
  }, [pedido])

  // async function checkUser() {

  // }

  //tipoMateriais
  async function loadTipoMateriais() {
    if (!pedido?.lojaId)
      return;

    if (tipoMateriais.lojaId === pedido.lojaId)
      return;

    const result = await GET_LIST_TIPO_MATERIAL({ lojaId: pedido.lojaId });

    if (result?.value) {
      let tipoMateriais = result.value;
      let materiaisComunicacao = [];

      for (let tipo of tipoMateriais) {
        for (let material of tipo.materiaisComunicacao)
          materiaisComunicacao.push(material)
      }

      materiaisComunicacao = materiaisComunicacao.sort((a, b) => ('' + a.nome).localeCompare(b.nome))

      setTipoMateriais({
        lojaId: pedido.lojaId,
        lista: result.value,
        materiaisComunicacao
      });
    }
  }

  //tipoEntregas
  async function loadTipoEntregas() {
    if (!tipoEntregas.length) {
      const result = await GET_LIST_TIPO_ENTREGA();

      if (result?.value?.length) {
        let tipoEntregas = result.value;
        setTipoEntregas(tipoEntregas);
      }
    }
  }

  //enderecoFilial
  async function loadEnderecoCentroDistribuicao() {
    if (!pedido?.lojaId)
      return;

    if (enderecoCentroDistribuicao.lojaId === pedido.lojaId)
      return;

    const result = await GET_ENDERECO_CENTRO_DISTRIBUICAO(pedido.lojaId);

    if (result.value) {
      setEnderecoCentroDistribuicao({ lojaId: pedido.lojaId, endereco: result.value });
    }
  }


  return (
    <ContextEntities.Provider value={{
      //tipoMateriais: tipoMateriais.lista,
      materiaisComunicacao: tipoMateriais.materiaisComunicacao,
      //, loadTipoMateriais,

      tipoEntregas,
      loadTipoEntregas,

      enderecoCentroDistribuicao: enderecoCentroDistribuicao.endereco,
      // loadEnderecoCentroDistribuicao
      // campanhas, loadCampanhas,
      // estados, loadEstados,
      // //unidades, loadUnidades,
      // //produtos, loadProdutos,
      // //produtoCategorias, loadProdutoCategorias,
      // motivos, loadMotivos,
      // extensoes, loadExtensoes,
      // formaPagamentos, loadFormaPagamentos,
      // //quantidadesImpressos, loadQuantidadesImpressos,
      // tempos, loadTempos,

      // enderecoFilial, loadEnderecoFilial,
      // campanhaMateriais, loadCampanhaMateriais,
      // materiaisCadastroItens, loadMateriaisCadastroItens,
      // clienteLayouts, loadClienteLayouts,
    }}>
      {props.children}
    </ContextEntities.Provider>
  );
};
