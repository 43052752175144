import React from 'react';

import Layout from 'components/Layout';
import TitlePages from 'components/_base/TitlePages';

import ContentDraft from './ContentDraft';

const Draft = () => {
  return (
    <>
      <Layout>
        <TitlePages title="Rascunhos" />
        <ContentDraft />
      </Layout>
    </>
  );
};

export default Draft;
