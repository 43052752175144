/* eslint-disable */
import React from 'react';
import { ContextPedido } from '../context-pedido';


export default function Dados(props) {
  const { pedido, setPedido } = React.useContext(ContextPedido);

  return <h1>Dados</h1>
}
