import React from 'react';
import './styles.scss';

const Atencao = () => {
  return <div className="page-atencao">
    <b>ATENÇÃO!!!</b>
    <div>1- Selecione corretamente o <b>TIPO DE MATERIAL</b>, após o envio da arte <b>NÃO</b> é possível trocar o formato.</div>
    <div>2 - Selecione corretamente o <b>LAYOUT</b> desejado, após envio da arte para aprovação <b>NÃO</b> é possível trocar.</div>
    <div>3- Informe corretamente as medidas de <b>LARGURA E ALTURA</b>, após o envio da arte para aprovação <b>NÃO</b> é possível alterar de vertical para horizontal.</div>
    <div>Em caso de dúvidas procure o atendimento antes de finalizar a solicitação</div>
  </div>;
};

export default Atencao;
