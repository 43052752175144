import api from 'api';

const PATH = '/Solicitacao';

const CREATE_RASCUNHO_COPIA_SOLICITACAO = async (solicitacaoId) => {
  const { data } = await api.post(
    `${PATH}/create-rascunho-copia-solicitacao/${solicitacaoId}`
  );
  return data;
};
const GET_LIST_ACAO_PROMOCIONAL_CATEGORIA = async () => {
  const { data } = await api.get(`${PATH}/get-list-acao-promocional-categoria`);
  return data;
};
const GET_LIST_CADASTRO_ITEM = async () => {
  const { data } = await api.get(`${PATH}/get-list-cadastro-item`);
  return data;
};
const GET_LIST_MATERIAL_CADASTRO_ITENS_MODEL = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-list-material-cadastro-itens-model`,
    {
      params: filtros,
    }
  );
  return data;
};
const GET_LIST_MATERIAL_CADASTRO_ITENS = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-material-cadastro-itens`, {
    params: filtros,
  });
  return data;
};
const GET_LIST_CAMPANHA = async () => {
  const { data } = await api.get(`${PATH}/get-list-campanha`);
  return data;
};
const GET_LIST_LAYOUT_ARTE = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-layoutarte`, {
    params: filtros,
  });
  return data;
};
const GET_URL_LOGO_PADRAO = async () => {
  const { data } = await api.get(`${PATH}/get-url-logo-padrao`);
  return data;
};
const GET_LIST_MATERIAL_CAMPANHA = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-material-campanha-model`, {
    params: filtros,
  });
  return data;
};
const GET_SOLICITACAO_PRODUTO = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-solicitacao-produto-model`, {
    params: filtros,
  });
  return data;
};
const GET_LIST_SOLICITACAO_PRODUTO_ESPECIFICO = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-list-solicitacao-produto-model-especificos`,
    {
      params: filtros,
    }
  );
  return data;
};
const GET_LIST_SOLICITACAO_PRODUTO_OBRIGATORIOS = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-list-solicitacao-produto-model-obrigatorios`,
    {
      params: filtros,
    }
  );
  return data;
};
const GET_SOLICITACAO = async (solicitacaoId) => {
  const { data } = await api.get(`${PATH}/get-solicitacao/${solicitacaoId}`);
  return data;
};
const GET_TAB_PRODUTOS = async () => {
  const { data } = await api.get(`${PATH}/get-tab-produtos-model`);
  return data;
};
const GET_CONFIG_PRODUTOS = async () => {
  const { data } = await api.get(`${PATH}/get-config-produtos`);
  return data;
};
const GET_LIST_CIDADE = async (ufValue) => {
  const { data } = await api.get(`${PATH}/get-list-cidade`, {
    params: {
      uf: ufValue,
    },
  });
  return data;
};
const GET_LIST_ESTADO = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-estado`, {
    params: filtros,
  });
  return data;
};
const GET_LIST_EXTENSAO_ARQUIVO = async () => {
  const { data } = await api.get(`${PATH}/get-list-extensao-arquivo`);
  return data;
};
const GET_LIST_FORMA_PAGAMENTO = async () => {
  const { data } = await api.get(`${PATH}/get-list-forma-pagamento`);
  return data;
};
const GET_LOJA_LAYOUT_ARTES = async (lojaId) => {
  const { data } = await api.get(
    `${PATH}/get-loja-layoutartes-model/${lojaId}`
  );
  return data;
};
const GET_LIST_MATERIAL = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-material`, {
    params: filtros,
  });
  return data;
};
const GET_LIST_MATERIAL_ARTES_FACIL = async () => {
  const { data } = await api.get(`${PATH}/get-list-material-arte-facil`);
  return data;
};
const GET_LIST_MOTIVO_ACAO = async () => {
  const { data } = await api.get(`${PATH}/get-list-motivo-acao`);
  return data;
};
const GET_LIST_MARCAS = async () => {
  const { data } = await api.get(`${PATH}/get-list-marcas`);
  return data;
};
const GET_LIST_CATEGORIA_LAYOUTS = async () => {
  const { data } = await api.get(`${PATH}/get-list-categoria-layout`);
  return data;
};
const GET_LAYOUT_PRODUTOS_ESPECIFICOS = async (layoutArteId) => {
  const { data } = await api.get(
    `${PATH}/get-layoutarte-produtos-especificos-model/${layoutArteId}`
  );
  return data;
};
const GET_LAYOUT_PRODUTOS_OBRIGATORIOS = async (layoutArteId) => {
  const { data } = await api.get(
    `${PATH}/get-layoutarte-produtos-obrigatorios-model/${layoutArteId}`
  );
  return data;
};
const GET_LIST_QUANTIDADE_IMPRESSOS = async () => {
  const { data } = await api.get(`${PATH}/get-list-quantidade-impressos`);
  return data;
};
const GET_LIST_TEMPO_VIDEO = async () => {
  const { data } = await api.get(`${PATH}/get-list-tempo-video`);
  return data;
};
const GET_LIST_TIPO_ARQUIVO = async () => {
  const { data } = await api.get(`${PATH}/get-list-tipo-arquivo`);
  return data;
};
const GET_LIST_TIPO_ENTREGA = async () => {
  const { data } = await api.get(`${PATH}/get-list-tipo-entrega`);
  return data;
};
const GET_LIST_TIPO_MATERIAL = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-tipo-material`, {
    params: filtros,
  });
  return data;
};
const GET_LIST_UNIDADE = async () => {
  const { data } = await api.get(`${PATH}/get-list-unidade`);
  return data;
};
const GET_LIST_SOLICITACAO_PRODUTO = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-solicitacao-produto-model`, {
    params: filtros,
  });
  return data;
};
const GET_LIST_LOJA = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-loja`, {
    params: filtros,
  });
  return data;
};
const UPDATE_SOLICITACAO_REPROVADA = async (dataBody) => {
  const { data } = await api.post(`${PATH}/update-solicitacao-reprovada`, {
    data: dataBody,
  });
  return data;
};
const UPDATE_SOLICITACAO_REPROVADA_WEB = async (dataBody) => {
  const { data } = await api.post(
    `${PATH}/update-solicitacao-reprovada-web`,
    dataBody
  );
  return data;
};
const UPLOAD_LOGO = async (dataBody) => {
  const { data } = await api.post(`${PATH}/upload-logo`, dataBody, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'WWW-Authenticate': `Bearer ${
        localStorage.getItem('encartes_dpa_site') == null
          ? undefined
          : localStorage.getItem('encartes_dpa_site').jwtToken
      }`,
    },
  });
  return data;
};
const UPLOAD_RODAPE = async (dataBody) => {
  const { data } = await api.post(`${PATH}/upload-rodape`, dataBody, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'WWW-Authenticate': `Bearer ${
        localStorage.getItem('encartes_dpa_site') == null
          ? undefined
          : localStorage.getItem('encartes_dpa_site').jwtToken
      }`,
    },
  });
  return data;
};
const UPLOAD_CARTAO_CLIENTE = async (dataBody) => {
  const { data } = await api.post(`${PATH}/upload-cartao-cliente`, dataBody, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'WWW-Authenticate': `Bearer ${
        localStorage.getItem('encartes_dpa_site') == null
          ? undefined
          : localStorage.getItem('encartes_dpa_site').jwtToken
      }`,
    },
  });
  return data;
};
const GET_LIST_LAYOUTARTE = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-loja`, {
    params: filtros,
  });
  return data;
};
const GET_LIST_LAYOUTARTES_MODEL = async (lojaId) => {
  const { data } = await api.get(`${PATH}/get-list-loja/${lojaId}`);
  return data;
};
const GET_ARTE_FACIL_CADASTRO_ITEM = async () => {
  const { data } = await api.get(`${PATH}/get-arte-facil-cadastro-item`);
  return data;
};
const GET_ARTE_FACIL_CADASTRO_ITENS = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-arte-facil-cadastro-item`, {
    params: filtros,
  });
  return data;
};
const GET_ARTE_PRODUTOS_SEM_IMAGEM = async () => {
  const { data } = await api.get(`${PATH}/get-arte-produtos-sem-imagem`);
  return data;
};
const GET_ARQUIVO_LOGO = async (lojaId) => {
  const { data } = await api.get(`${PATH}/get-arquivo-logo/${lojaId}`);
  return data;
};
const GET_ARQUIVO_RODAPE = async (lojaId) => {
  const { data } = await api.get(`${PATH}/get-arquivo-rodape/${lojaId}`);
  return data;
};
const GET_ENDERECO_CENTRO_DISTRIBUICAO = async (lojaId) => {
  const { data } = await api.get(
    `${PATH}/get-endereco-centro-distribuicao/${lojaId}`
  );
  return data;
};
const GET_SELECT_LIST_CANAL = async () => {
  const { data } = await api.get(`${PATH}/get-select-list-canal`);
  return data;
};
const GET_SELECT_LIST_REGIONAL = async () => {
  const { data } = await api.get(`${PATH}/get-select-list-regional`);
  return data;
};
const GET_SELECT_LIST_REDE = async () => {
  const { data } = await api.get(`${PATH}/get-select-list-rede`);
  return data;
};
const ADD_LOJA = async (dataBody) => {
  const { data } = await api.post(`${PATH}/add-loja`, dataBody);
  return data;
};
export {
  GET_SELECT_LIST_REDE,
  GET_SELECT_LIST_REGIONAL,
  GET_SELECT_LIST_CANAL,
  GET_ARQUIVO_RODAPE,
  GET_ENDERECO_CENTRO_DISTRIBUICAO,
  GET_ARTE_FACIL_CADASTRO_ITEM,
  GET_LIST_LAYOUTARTE,
  GET_LIST_LAYOUTARTES_MODEL,
  GET_ARTE_PRODUTOS_SEM_IMAGEM,
  GET_ARTE_FACIL_CADASTRO_ITENS,
  UPDATE_SOLICITACAO_REPROVADA,
  UPLOAD_RODAPE,
  UPLOAD_CARTAO_CLIENTE,
  GET_ARQUIVO_LOGO,
  UPDATE_SOLICITACAO_REPROVADA_WEB,
  UPLOAD_LOGO,
  CREATE_RASCUNHO_COPIA_SOLICITACAO,
  GET_LIST_LOJA,
  GET_LIST_SOLICITACAO_PRODUTO,
  GET_LIST_UNIDADE,
  GET_LIST_TIPO_MATERIAL,
  GET_LIST_TIPO_ENTREGA,
  GET_LIST_TIPO_ARQUIVO,
  GET_LIST_TEMPO_VIDEO,
  GET_LIST_ACAO_PROMOCIONAL_CATEGORIA,
  GET_LIST_QUANTIDADE_IMPRESSOS,
  GET_LAYOUT_PRODUTOS_OBRIGATORIOS,
  GET_LAYOUT_PRODUTOS_ESPECIFICOS,
  GET_LIST_CADASTRO_ITEM,
  GET_LIST_MOTIVO_ACAO,
  GET_LIST_MARCAS,
  GET_LIST_CATEGORIA_LAYOUTS,
  GET_LIST_MATERIAL_CADASTRO_ITENS,
  GET_LIST_MATERIAL_CADASTRO_ITENS_MODEL,
  GET_LIST_CAMPANHA,
  GET_LIST_MATERIAL_ARTES_FACIL,
  GET_LIST_LAYOUT_ARTE,
  GET_LIST_SOLICITACAO_PRODUTO_ESPECIFICO,
  GET_LIST_MATERIAL,
  GET_URL_LOGO_PADRAO,
  GET_LIST_MATERIAL_CAMPANHA,
  GET_SOLICITACAO_PRODUTO,
  GET_LIST_SOLICITACAO_PRODUTO_OBRIGATORIOS,
  GET_SOLICITACAO,
  GET_TAB_PRODUTOS,
  GET_CONFIG_PRODUTOS,
  GET_LIST_CIDADE,
  GET_LIST_ESTADO,
  GET_LIST_EXTENSAO_ARQUIVO,
  GET_LIST_FORMA_PAGAMENTO,
  GET_LOJA_LAYOUT_ARTES,
  ADD_LOJA,
};
