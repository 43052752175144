/* eslint-disable */
import React from 'react';
import { ContextPedido } from '../context-pedido';
import { ContextSolicitacao } from './context-solicitacao';

import { GET_CONFIG_PRODUTOS, GET_LIST_LAYOUT_ARTE } from 'services/Solicitacao';

export const ContextEntitiesSolicitacao = React.createContext({});

export const ContextEntitiesSolicitacaoProvider = props => {
  const { pedido } = React.useContext(ContextPedido);
  const { solicitacao } = React.useContext(ContextSolicitacao)

  const [layouts, setLayouts] = React.useState({
    lojaId: 0,
    materialComunicacaoId: 0,
    lista: []
  });

  const [configProdutos, setConfigProdutos] = React.useState({
    materialComunicacaoId: 0,
    layoutArteId: 0,
    config: {}
  })

  React.useEffect(() => {
    console.log('useEffect-entities-solicitacao1')
    loadLayouts();
  }, [solicitacao.materialComunicacaoId])

  React.useEffect(() => {
    console.log('useEffect-entities-solicitacao2')
    loadConfigProdutos();
  }, [solicitacao.materialComunicacaoId, solicitacao.solicitacaoDados?.layoutArteId])

  async function loadLayouts() {
    if (!solicitacao?.materialComunicacaoId || !pedido?.lojaId)
      return;

    if (layouts.materialComunicacaoId === solicitacao.materialComunicacaoId && layouts.lojaId === pedido.lojaId)
      return;

    const result = await GET_LIST_LAYOUT_ARTE({materialComunicacaoId: solicitacao.materialComunicacaoId, lojaId: pedido.lojaId});

    if (result?.value?.length) {
      let lista = result.value;

      setLayouts({
        lojaId: pedido.lojaId,
        materialComunicacaoId: solicitacao.materialComunicacaoId,
        lista: lista
      });
    }
  }

  async function loadConfigProdutos() {
    if (!solicitacao?.materialComunicacaoId || !solicitacao?.solicitacaoDados?.layoutArteId)
      return;

    if (configProdutos.materialComunicacaoId === solicitacao.materialComunicacaoId && configProdutos.layoutArteId === solicitacao.solicitacaoDados.layoutArteId)
      return;

    const result = await GET_CONFIG_PRODUTOS({materialComunicacaoId: solicitacao.materialComunicacaoId, layoutArteId: solicitacao.solicitacaoDados.layoutArteId});

    if (result?.value) {
      let config = result.value;

      setConfigProdutos({
        materialComunicacaoId: solicitacao.materialComunicacaoId,
        layoutArteId: solicitacao.solicitacaoDados.layoutArteId,
        config: config
      });
    }
  }

  return (
    <ContextEntitiesSolicitacao.Provider value={{
      layouts: layouts.lista,
      configProdutos: configProdutos.config
    }}>
      {props.children}
    </ContextEntitiesSolicitacao.Provider>
  );
};
