/* eslint-disable */
import React from 'react';
import { ContextPedido } from '../context-pedido';

export default function ResumoSolicitacao({ solicitacao }) {

  return (
    <div>
      <h1>ResumoSolicitacao</h1>

      <label>material: {solicitacao?.layoutArte?.nome}</label>
    </div>
  )
}
